import { cn } from "@/lib/utils";
import { FormControl, FormLabel } from "./ui/form";
import { Input } from "./ui/input";
import { forwardRef } from "react";

function InputWithFloatingLabel(
  { label, className, labelClassName, ...rest }: any,
  ref: any
) {
  return (
    <div className="relative z-0 mt-5">
      <FormLabel className={cn(labelClassName)}>{label}</FormLabel>
      <FormControl>
        <Input
          ref={ref}
          type="text"
          className={cn(className)}
          {...rest}
          placeholder=""
        />
      </FormControl>
    </div>
  );
}

export default forwardRef(InputWithFloatingLabel);
