import { AbnormalDetails } from "@/types/AbnormalDetails";
import RibbonImgLarge from "../assets/img/ribbon-blue-large.png";
import SpinnerImg from "../assets/img/spinner.gif";
import PhotoOne from "../assets/img/photo_one_urine_16x9.jpg";
import PhotoThree from "../assets/img/photo_three_black_area.jpg";
import PhotoFour from "../assets/img/pre-scan-image.jpg";
import ribbonLoop from "../assets/mp4/Ribbon_Dip_Loop_Compressed.mp4";
import lottieTwo from "../assets/lotties/2lottie.json";
import lottieFour from "../assets/lotties/4lottie.json";

import KidneyImg from "../assets/img/kidney.png";
import LiverImg from "../assets/img/liver.png";
import DiabetesImg from "../assets/img/diabetes.png";
import InfectionImg from "../assets/img/infection.png";
import HydrationImg from "../assets/img/hydration.png";
import PHImg from "../assets/img/ph.png";
import { PrefixType, SuffixType } from "@/types/SuffixType";

export const QUERY_KEY = {
  scanResults: "scanResults",
};

const SERVER_URL = process.env.REACT_APP_ALGO_API;

const DETAIL = [
  "leu",
  "nit",
  "uro",
  "pro",
  "ph",
  "blo",
  "sg",
  "ket",
  "bil",
  "glu",
];

export const PARAMETER_TITLE: { [key: string]: string } = {
  leu: "Leukocytes",
  nit: "Nitrite",
  uro: "Urobilinogen",
  pro: "Protein",
  ph: "pH",
  blo: "Blood",
  sg: "Specific Gravity",
  ket: "Ketone",
  bil: "Bilirubin",
  glu: "Glucose",
};

const PREFIX: PrefixType = {
  leu: ["Negative", "Trace", "Small", "Moderate", "Large"],
  nit: ["Negative", "Positive", "Positive"],
  uro: ["Normal", "Normal", "Trace", "Small", "Moderate", "Large"],
  pro: ["Negative", "Trace", "Small", "Moderate", "Large", "Large"],
  ph: ["5.0", "6.0", "6.5", "7.0", "7.5", "8.0", "8.5"],
  blo: ["Negative", "Trace", "Small", "Moderate", "Large"],
  sg: ["1.000", "1.005", "1.010", "1.015", "1.020", "1.025", "1.030"],
  ket: ["Negative", "Trace", "15", "40", "80", "160"],
  bil: ["Negative", "Small", "Moderate", "Large"],
  glu: ["Negative", "100", "250", "500", "1000", "2000"],
  // "glu": ["Negative", "Trace", "Small", "Moderate", "Large", "X Large"],
};

const SUFFIX: SuffixType = {
  leu: ["", "", "+", "++", "+++"],
  nit: ["", "+", "+"],
  uro: ["", "", "+", "++", "+++", "++++"],
  pro: ["", "", "+", "++", "+++", "++++"],
  ph: ["", "", "", "", "", "", ""],
  blo: ["", "", "+", "++", "+++"],
  sg: ["", "", "", "", "", "", ""],
  ket: ["", "", "+", "++", "+++", "++++"],
  bil: ["", "+", "++", "+++"],
  glu: ["", "", "+", "++", "+++", "++++"],
};

const ABNORMAL_DETAILS: AbnormalDetails = {
  leu: "Indicates a possible urinary tract infection or inflammation.",
  nit: "Suggests the presence of certain bacteria that can cause urinary tract infections.",
  uro: "Elevated levels may indicate liver problems or hemolytic anemia.",
  pro: "Presence may suggest kidney disease, urinary tract infections, or other disorders.",
  ph: "Abnormal pH levels can indicate kidney problems, urinary tract infections, or certain metabolic conditions.",
  blo: "Presence may indicate kidney or urinary tract problems, infections, or certain cancers.",
  sg: "Abnormal levels suggest the body's hydration status or kidney's concentrating ability.",
  ket: "Presence may indicate uncontrolled diabetes, starvation, or a high-fat, low-carbohydrate diet.",
  bil: "Elevated levels suggest liver problems, bile duct obstruction, or certain blood disorders.",
  glu: "Presence may indicate diabetes, kidney problems, or other metabolic disorders.",
};

export const images = [
  RibbonImgLarge,
  PhotoOne,
  PhotoThree,
  PhotoFour,
  SpinnerImg,
];

export const STATE_LIST = [
  {
    key: "kidney",
    img: KidneyImg,
    title: "Kidney Health",
    parameters: ["pro", "blo"],
  },
  {
    key: "liver",
    img: LiverImg,
    title: "Liver Function",
    parameters: ["bil", "uro"],
  },
  {
    key: "diabetes",
    img: DiabetesImg,
    title: "Glucose Levels",
    parameters: ["glu", "ket"],
  },
  {
    key: "infection",
    img: InfectionImg,
    title: "Infection",
    parameters: ["leu", "nit"],
  },
  { key: "hydration", img: HydrationImg, title: "Hydration", parameters: [] },
  { key: "ph", img: PHImg, title: "pH Levels", parameters: [] },
];

export const INITIAL_USER_DATA = {
  leu: { title: "White Blood Cell", value: 1, color: [0, 0, 0] },
  nit: { title: "Bacteria Test", value: 1, color: [0, 0, 0] },
  uro: { title: "1st Liver Test", value: 1, color: [0, 0, 0] },
  pro: { title: "1st Kidney Test", value: 1, color: [0, 0, 0] },
  ph: { title: "pH Test", value: 1, color: [0, 0, 0] },
  blo: { title: "2nd Kidney Test", value: 1, color: [0, 0, 0] },
  sg: { title: "Hydration Test", value: 1, color: [0, 0, 0] },
  ket: { title: "1st Glucose Test", value: 1, color: [0, 0, 0] },
  bil: { title: "2nd Liver Test", value: 1, color: [0, 0, 0] },
  glu: { title: "2nd Glucose Test", value: 1, color: [0, 0, 0] },
};

export const INITIAL_DOCTOR_DATA = {
  leu: { title: "Leukocytes", value: "0", color: [0, 0, 0], prediction: 0 },
  nit: { title: "Nitrite", value: "0", color: [0, 0, 0], prediction: 0 },
  uro: { title: "Urobilinogen", value: "0", color: [0, 0, 0], prediction: 0 },
  pro: { title: "Protein", value: "0", color: [0, 0, 0], prediction: 0 },
  ph: { title: "pH", value: "0", color: [0, 0, 0], prediction: 0 },
  blo: { title: "Blood", value: "0", color: [0, 0, 0], prediction: 0 },
  sg: {
    title: "Specific Gravity",
    value: "0",
    color: [0, 0, 0],
    prediction: 0,
  },
  ket: { title: "Ketone", value: "0", color: [0, 0, 0], prediction: 0 },
  bil: { title: "Bilirubin", value: "0", color: [0, 0, 0], prediction: 0 },
  glu: { title: "Glucose", value: "0", color: [0, 0, 0], prediction: 0 },
};

export const SCAN_RESULT_STORAGE_KEY = "scanResultData";

export { SERVER_URL, ABNORMAL_DETAILS, DETAIL, PREFIX, SUFFIX };
