import { cn } from "@/lib/utils";
import { useMemo } from "react";

interface IProps {
  max: number;
  value: number;
}

export default function PredictionRange({ max, value }: IProps) {
  const percentage = Math.round((value / max) * 100);
  const color = percentage < 50 ? "#99CB67" : "#EE6365";
  const c = 100 / (max + 1);
  const offset = c * value + c / 2;

  const fragments = useMemo(() => {
    const fragments = [];

    for (let i = 0; i <= max; i++) {
      const isActive = i === value;
      fragments.push(
        <div
          key={i}
          className="h-[3px] rounded bg-gray-200"
          style={{ background: isActive ? color : undefined }}
        />
      );
    }

    return fragments;
  }, [color, max, value]);

  const label =
    percentage === 0 ? "Negative" : percentage < 50 ? "Small" : "Large";

  return (
    <div className="w-[120px] pt-[30px]">
      <div className="w-full relative mb-[5px] h-[8px]">
        <div
          className={cn(
            "text-sm text-ribbon-slate-700 mb-1",
            "absolute bottom-[10px] -translate-x-2/4"
          )}
          style={{ left: offset + "%" }}
        >
          {label}
        </div>
        <div
          className={cn(
            "w-0 h-0 border-transparent border-l-[6px] border-r-[6px] border-t-[8px] border-t-black -translate-x-2/4",
            "absolute top-0"
          )}
          style={{ left: offset + "%" }}
        />
      </div>
      <div
        className="w-full grid"
        style={{ gridTemplateColumns: `repeat(${max + 1}, 1fr)`, gap: 3 }}
      >
        {fragments}
      </div>
    </div>
  );
}
