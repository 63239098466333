import Header from "@/components/AppHeader";
import ButtonsContainer from "@/components/ButtonsContainer";
import { Button } from "@/components/ui/button";
import useTimer, { TimerStatus } from "@/hooks/useTimer";
import { IScanStepMedia, ScanMediaType } from "@/types/Scan";
import {
  ANALYZING_STRIP_STEP_ID,
  CONFIRM_PHOTO_STEP_ID,
  DIP_STEP_ID,
  ERROR_STEP_ID,
  RESULT_STEP_ID,
  STEPS,
} from "@/utils/scanUtils";
import { useSpring, animated, useSpringRef } from "@react-spring/web";
import { isNumber, padStart } from "lodash";
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Timer as TimerIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import axios, { AxiosResponse } from "axios";
import { QUERY_KEY, SERVER_URL } from "@/utils/variables";
import { IScanResult } from "@/types/ScanResult";
import AnalyzeAnimation from "./AnalyzeAnimation";
import Result from "./Result";
import { useAppContext } from "@/contexts/appContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabaseService } from "@/services/supabaseService";
import posthog from "posthog-js";
import AlertMP3 from "@/assets/audio/alert.mp3";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { PopupButton } from "@typeform/embed-react";
import { Chance } from "chance";
import Cookies from "js-cookie";
import { toast } from "sonner";

const audioElement = new Audio(AlertMP3);
audioElement.loop = true;

const TYPEFORM_SURVEY_ID = process.env.REACT_APP_TYPEFORM_POST_SCAN_SURVEY_ID;
const TYPEFORM_SURVEY_PROBABILITY = Number(
  process.env.REACT_APP_POST_SCAN_SURVEY_PROBABILITY
);
const TYPEFORM_SURVEY_DELAY = Number(
  process.env.REACT_APP_POST_SCAN_SURVEY_DELAY_MILISECONDS
);
const SHOWN_SURVEY_ID_COOKIE_KEY = "shown_post_scan_survey_id";
const chance = new Chance();

const timerLengthVar = Number(process.env.REACT_APP_TIMER_LENGTH);
const timerLengthInSeconds =
  isNumber(timerLengthVar) && isFinite(timerLengthVar) ? timerLengthVar : 120;

export default function Scan() {
  const { stepId } = useParams();
  const timer = useTimer({ time: timerLengthInSeconds * 1000 });
  const [photoFile, setPhotoFile] = useState<File | undefined>(undefined);
  const [resultData, setResultData] = useState<IScanResult | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const { session } = useAppContext();
  const queryClient = useQueryClient();
  const supabase = supabaseService.getClient();
  const [openSurveyDialog, setOpenSurveyDialog] = useState(false);

  const typeformPopupRef = useRef();

  const mutationSaveResultToSupabase = useMutation({
    mutationFn: async (data: { resultData: IScanResult; userId: string }) => {
      const payload = {
        user_uid: data.userId,
        scan_uid: data.resultData.scan_uuid,
      };

      const { error } = await supabase.from("UserScan").insert(payload);

      if (error) throw error;
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.scanResults] });
    },
  });

  const { step, stepIndex } = useMemo(() => {
    const foundStepIndex = STEPS.findIndex((step) => step.id === stepId);
    const stepIndex = foundStepIndex >= 0 ? foundStepIndex : 0;
    const step = STEPS[stepIndex];
    return { step, stepIndex };
  }, [stepId]);

  const isTimerExpired = useMemo(() => {
    return timer.status === TimerStatus.Expired;
  }, [timer.status]);

  const saveResultData = useCallback(
    (resultData: IScanResult) => {
      if (session?.user) {
        // save result to the database if the user logged in
        mutationSaveResultToSupabase.mutate({
          resultData,
          userId: session.user.id,
        });
      }
    },
    [mutationSaveResultToSupabase, session?.user]
  );

  const handleChangePicture = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        setPhotoFile(file);

        if (step.id !== CONFIRM_PHOTO_STEP_ID) {
          navigate(`/scan/${CONFIRM_PHOTO_STEP_ID}`, { replace: true });
        }
      }
    },
    [navigate, step]
  );

  const handleConfirmPhoto = useCallback(async () => {
    if (!photoFile) return;

    try {
      navigate(`/scan/${ANALYZING_STRIP_STEP_ID}`, { replace: true });
      const formData = new FormData();
      formData.append("image", photoFile);
      const response = await axios.post<any, AxiosResponse<IScanResult>>(
        SERVER_URL || "",
        formData
      );
      setResultData(response.data);
      saveResultData(response.data);

      // used to track how long the strip develops for.
      let stripDevelopmentTime;
      if (sessionStorage.getItem("stripDipStartTime")) {
        const stripDipStartTime = parseInt(
          sessionStorage.getItem("stripDipStartTime") || ""
        );
        stripDevelopmentTime = Math.abs(Date.now() - stripDipStartTime) / 1000;
      }

      if (["prod", "stg"].includes(process.env.REACT_APP_ENV || "")) {
        posthog.capture("scan_succeeded", {
          scan_order_no: response.data.scan_order_no,
          scan_uuid: response.data.scan_uuid,
          strip_development_time: stripDevelopmentTime,
        });
      }
      sessionStorage.removeItem("stripDipStartTime");
    } catch (error) {
      console.error(error);
      navigate(`/scan/${ERROR_STEP_ID}`, { replace: true });
    }
  }, [navigate, photoFile, saveResultData]);

  const handleAnalyzeAnimationIdle = useCallback(() => {
    if (!resultData) return;
    navigate(`/scan/${RESULT_STEP_ID}`, { replace: true });
  }, [navigate, resultData]);

  const stopAlarmSound = () => {
    audioElement.pause();
  };

  const playAlarSound = useCallback(() => {
    try {
      audioElement.play();
      setTimeout(stopAlarmSound, 6000);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const openTypeformPopup = useCallback(() => {
    if (typeformPopupRef.current) (typeformPopupRef.current as any).open();
    setOpenSurveyDialog(false);
  }, []);

  const showTypeformSurveyDialog = useCallback(() => {
    const shownSurveyId = Cookies.get(SHOWN_SURVEY_ID_COOKIE_KEY);

    if (!TYPEFORM_SURVEY_ID || shownSurveyId) return;

    const showDialogProbability =
      isNumber(TYPEFORM_SURVEY_PROBABILITY) &&
      isFinite(TYPEFORM_SURVEY_PROBABILITY)
        ? Math.min(TYPEFORM_SURVEY_PROBABILITY * 100, 100)
        : 0;
    const showDialogDelay =
      isNumber(TYPEFORM_SURVEY_DELAY) && isFinite(TYPEFORM_SURVEY_DELAY)
        ? TYPEFORM_SURVEY_DELAY
        : 0;
    const shouldDisplayDialog = chance.bool({
      likelihood: showDialogProbability,
    });

    if (shouldDisplayDialog) {
      setTimeout(() => {
        setOpenSurveyDialog(true);
        Cookies.set(SHOWN_SURVEY_ID_COOKIE_KEY, TYPEFORM_SURVEY_ID);
      }, showDialogDelay);
    }
  }, []);

  const handleSubmitTypeformSurvey = useCallback(() => {
    setOpenSurveyDialog(false);
    (typeformPopupRef.current as any).close();
    toast.success("Thank you for completing this survey!")
  }, []);

  const animationRef = useSpringRef();

  const [fadeAnimation] = useSpring(
    () => ({
      ref: animationRef,
    }),
    []
  );

  useEffect(() => {
    animationRef.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: {
        friction: 20,
        clamp: true,
      },
    });
  }, [step, animationRef]);

  const renderButtons = useCallback(() => {
    const previousStepId = stepIndex > 0 ? STEPS[stepIndex - 1].id : undefined;
    const nextStepId = STEPS[stepIndex + 1]?.id;

    switch (step.id) {
      case ERROR_STEP_ID:
        return (
          <>
            <TakePhotoButton
              disabled={step.takePicture && !isTimerExpired}
              variant="outline"
              onChangePicture={handleChangePicture}
              className="w-full"
            >
              Retake Photo
            </TakePhotoButton>
          </>
        );
      case ANALYZING_STRIP_STEP_ID:
        return null;
      case CONFIRM_PHOTO_STEP_ID:
        return (
          <>
            <Button
              className="grow"
              variant="outline"
              onClick={handleConfirmPhoto}
            >
              Yes
            </Button>
            <TakePhotoButton
              disabled={step.takePicture && !isTimerExpired}
              variant="link"
              onChangePicture={handleChangePicture}
            >
              Retake Photo
            </TakePhotoButton>
          </>
        );
      default:
        return (
          <>
            {step.takePicture ? (
              <>
                <TakePhotoButton
                  className="grow"
                  disabled={step.takePicture && !isTimerExpired}
                  variant="outline"
                  onChangePicture={handleChangePicture}
                >
                  {step.btnLabel}
                </TakePhotoButton>
              </>
            ) : (
              <>
                <Button asChild variant="outline" className="grow">
                  <Link to={`/scan/${nextStepId}`} replace>
                    {step.btnLabel}
                  </Link>
                </Button>
              </>
            )}
            {previousStepId && (
              <Button asChild variant="link">
                <Link to={`/scan/${previousStepId}`}>Back</Link>
              </Button>
            )}
          </>
        );
    }
  }, [
    stepIndex,
    step.id,
    step.takePicture,
    step.btnLabel,
    handleConfirmPhoto,
    isTimerExpired,
    handleChangePicture,
  ]);

  useEffect(() => {
    if (step.metadata?.showTimer) {
      timer.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step.metadata?.showTimer]);

  useEffect(() => {
    if (
      step.id === DIP_STEP_ID &&
      !sessionStorage.getItem("stripDipStartTime")
    ) {
      sessionStorage.setItem("stripDipStartTime", Date.now().toString());
    }
  }, [step.id]);

  useEffect(() => {
    if (timer.status === TimerStatus.Expired) playAlarSound();
  }, [playAlarSound, timer.status]);

  useEffect(() => {
    stopAlarmSound();
  }, [stepId]);

  useEffect(() => {
    if (step.id === RESULT_STEP_ID && resultData) {
      showTypeformSurveyDialog();
    }
  }, [resultData, step, showTypeformSurveyDialog]);

  const Buttons = renderButtons();

  return (
    <>
      {step.id === RESULT_STEP_ID && resultData ? (
        <>
          <Result data={resultData} />
        </>
      ) : (
        <>
          <Header />
          <div className="mt-5 flex flex-col grow">
            {step && (
              <>
                <div className="px-6 w-full max-w-screen-sm mx-auto">
                  <animated.div style={fadeAnimation}>
                    {step.media && (
                      <div className="relative">
                        <Media
                          media={
                            typeof step.media === "function"
                              ? step.media({ stipPictureFile: photoFile })
                              : step.media
                          }
                        />
                        {step.metadata?.showTimer &&
                          timer.status !== TimerStatus.Stopped && (
                            <Timer
                              time={timer.time}
                              isExpired={isTimerExpired}
                            />
                          )}
                      </div>
                    )}
                    <div className="my-6 w-full">
                      <h2 className="mb-4 text-2.5xl font-medium">
                        {step.title}
                      </h2>
                      {step.description && (
                        <p className="mb-4">{step.description}</p>
                      )}
                      {step.id === ANALYZING_STRIP_STEP_ID && (
                        <>
                          <AnalyzeAnimation
                            scanResult={resultData}
                            colorsCount={14}
                            onAnimationIdle={handleAnalyzeAnimationIdle}
                          />
                        </>
                      )}
                    </div>
                  </animated.div>
                </div>
                {Buttons && (
                  <div className="w-full mt-auto sticky bottom-0">
                    <div className="px-6 w-full max-w-screen-sm mx-auto mt-auto bg-white">
                      <ButtonsContainer>{Buttons}</ButtonsContainer>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
      {TYPEFORM_SURVEY_ID && (
        <>
          <Dialog open={openSurveyDialog} onOpenChange={setOpenSurveyDialog}>
            <DialogContent onPointerDownOutside={(e) => e.preventDefault()}>
              <DialogHeader></DialogHeader>
              <DialogTitle className="text-center text-2xl font-medium">
                Review your experience for a $15 Amazon Gift Card?
                You will be returned to your results after completing the survey.
              </DialogTitle>
              <Button onClick={openTypeformPopup}>Take Survey</Button>
            </DialogContent>
          </Dialog>
          <PopupButton
            embedRef={typeformPopupRef}
            id={TYPEFORM_SURVEY_ID}
            onSubmit={handleSubmitTypeformSurvey}
          />
        </>
      )}
    </>
  );
}

function Media({ media }: { media: IScanStepMedia }) {
  switch (media.type) {
    case ScanMediaType.Image:
      return (
        <img
          className="max-h-[55vh] mx-auto rounded-lg"
          src={media.src}
          alt="step_img"
        />
      );
    case ScanMediaType.Video:
      return (
        <video
          className="w-full h-100 rounded-lg"
          playsInline
          muted
          autoPlay
          preload="auto"
          loop
          style={{
            pointerEvents: "none",
          }}
        >
          <source src={media.src} type="video/mp4" />
        </video>
      );
    default:
      return null;
  }
}

function Timer({
  time,
  isExpired = false,
}: {
  time: number;
  isExpired?: boolean;
}) {
  const timeInSeconds = time / 1000;
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;
  const minutesStr = padStart(minutes.toString(), 2, "0");
  const secondsStr = padStart(seconds.toString(), 2, "0");

  return (
    <div
      className={cn(
        "absolute bottom-3 right-3 py-1 px-2 rounded-lg flex gap-1 items-center transition bg-ribbon-teal-300 text-xl min-w-24",
        "[&.timer-expire]:bg-ribbon-red [&.timer-expire_*]:text-white",
        timeInSeconds <= 10 ? "timer-expire" : undefined
      )}
    >
      <TimerIcon className="w-5 h-5" />
      <span className="transition">
        {minutesStr}:{secondsStr}
      </span>
    </div>
  );
}

function TakePhotoButton({
  disabled = false,
  children,
  className = "",
  variant = "outline",
  onChangePicture = () => {},
}: {
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  variant?: "outline" | "link";
  onChangePicture?: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  const id = "take-photo";

  return (
    <label className={className} htmlFor={id}>
      <Button asChild className="w-full" variant={variant} disabled={disabled}>
        {disabled ? (
          <button>{children}</button>
        ) : (
          <span className="cursor-pointer">{children}</span>
        )}
      </Button>
      <input
        id={id}
        type="file"
        accept="image/*"
        className="hidden"
        hidden
        capture
        onChange={onChangePicture}
        disabled={disabled}
      />
    </label>
  );
}
