/* eslint-disable jsx-a11y/anchor-is-valid */
import AuthCodeInput from "@/components/AuthCodeInput";
import { Button } from "@/components/ui/button";
import { ISignUpStepProps } from "@/types/common";
import { useCallback, useState } from "react";
import { toast } from "sonner";

function AuthCode({
  isLoading,
  onStepSubmit,
  length = 6,
}: ISignUpStepProps & { length?: number }) {
  const [otp, setOtp] = useState("");

  const handleSubmit = useCallback(
    (resend = false) => {
      if (resend) {
        onStepSubmit({ resend });
        setOtp("");
      } else {
        const code = otp;
        if (code.length !== length) {
          toast.error("Invalid code");
        } else {
          onStepSubmit({ code });
        }
      }
    },
    [length, onStepSubmit, otp]
  );

  return (
    <>
      <div className="grow flex flex-col">
        <AuthCodeInput value={otp} onChange={setOtp} />
        <div className="my-6 text-center text-gray-500 px-4">
          Didn’t get a code?{" "}
          <Button
            variant="link"
            className="p-0 h-auto"
            onClick={() => handleSubmit(true)}
          >
            Send again
          </Button>
        </div>
        <div className="mt-auto text-center py-6 sticky bottom-0 bg-white">
          <div className="flex">
            <Button
              variant="outline"
              className="grow"
              disabled={isLoading}
              onClick={() => handleSubmit()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthCode;
