import Header from "@/components/AppHeader";
import { Button } from "@/components/ui/button";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as zod from "zod";
import { Form, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Link, useNavigate } from "react-router-dom";
import { supabaseService } from "@/services/supabaseService";
import { toast } from "sonner";
import AuthCodeInput from "@/components/AuthCodeInput";
import { useAppContext } from "@/contexts/appContext";
import { handleOtpSendError } from "@/utils/common";
import PhoneNumberInput from "@/components/PhoneNumberInput";
import { isValidPhoneNumber } from "libphonenumber-js";

interface ISignInFormData {
  phoneNumber: string;
}

const FormSchema = zod.object({
  phoneNumber: zod
    .string()
    .min(1)
    .refine((value) => {
      const result = isValidPhoneNumber(value);
      return result;
    }),
});

export default function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [authReqestSent, setAuthRequestSent] = useState(false);
  const form = useForm<zod.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      phoneNumber: "",
    },
  });
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const { reloadSession } = useAppContext();

  const renderHeaderContent = useCallback(() => {
    return (
      <>
        <div className="text-ribbon-slate-gray">
          <h2 className="font-medium text-2xl">Sign In To Your Account</h2>
        </div>
      </>
    );
  }, []);

  const onSubmit = useCallback(async (data: ISignInFormData) => {
    setIsLoading(true);
    const supabaseClient = supabaseService.getClient();
    const { error } = await supabaseClient.auth.signInWithOtp({
      phone: data.phoneNumber,
      options: {
        shouldCreateUser: false,
      },
    });

    if (error) {
      handleOtpSendError(error);
    } else {
      toast.success("Code has been sent");
      setAuthRequestSent(true);
    }

    setIsLoading(false);
  }, []);

  const handleVerifyCode = useCallback(async () => {
    const code = otp;

    setIsLoading(true);
    const supabase = supabaseService.getClient();

    const { error } = await supabase.auth.verifyOtp({
      phone: form.getValues("phoneNumber"),
      token: code,
      type: "sms",
    });

    if (error) {
      const message = error.message || "Error";
      toast.error(message);
    } else {
      toast.success("Success");
      await reloadSession();
      navigate("/", { replace: true });
    }

    setIsLoading(false);
  }, [form, navigate, otp, reloadSession]);

  const handleSubmit = form.handleSubmit(onSubmit);

  return (
    <div className="px-6 mt-10 grow flex flex-col max-w-screen-sm w-full mx-auto">
      <Header renderContent={renderHeaderContent} />
      {authReqestSent ? (
        <>
          <div>
            <AuthCodeInput className="mt-5" onChange={setOtp} value={otp} />
          </div>
          <Button
            variant="outline"
            className="mt-8 w-full h-12 text-base"
            disabled={!form.formState.isValid || isLoading}
            onClick={handleVerifyCode}
          >
            Sign In
          </Button>
        </>
      ) : (
        <>
          <Form {...form}>
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem className="space-y-0">
                  <PhoneNumberInput
                    value={field.value}
                    onChange={field.onChange}
                    label="Cell Phone Number"
                  />
                  <FormMessage className="px-4" />
                </FormItem>
              )}
            />
            <Button
              variant="outline"
              className="mt-8 w-full h-12 text-base"
              disabled={!form.formState.isValid || isLoading}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
          </Form>
        </>
      )}
      <div className="mt-6 text-gray-500 px-4">
        Don’t have an account yet?{" "}
        <Button asChild variant="link" className="p-0 h-auto">
          <Link to="/sign-up">Sign Up</Link>
        </Button>
      </div>
    </div>
  );
}
