import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  useLoaderData,
} from "react-router-dom";
// import posthog from "posthog-js";
import Home from "./pages/Home";
import Scan from "./pages/Scan/Scan";
import SignIn from "./pages/SignIn";
import Tutorial from "./pages/Tutorial/Tutorial";
import YourUrine from "./pages/Tutorial/YourUrine";
import OurGoal from "./pages/Tutorial/OurGoal";
import SignUp from "./pages/SignUp/SignUp";
import { AppContextProvider } from "./contexts/appContext";
import AppLayout from "./components/AppLayout";
import { supabaseService } from "./services/supabaseService";
import ResultsPage from "./pages/ResultsPage";
import SingleResultPage from "./pages/SingleResultPage";

const sessionLoader = async () => {
  const {
    data: { session },
  } = await supabaseService.getClient().auth.getSession();

  return session;
};

const AuthRoutes = () => {
  const session = useLoaderData();
  return session ? <Navigate to="/" replace /> : <Outlet />;
};

const ProtectedRoutes = () => {
  const session = useLoaderData();
  return !session ? <Navigate to="/" replace /> : <Outlet />;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      loader={sessionLoader}
      element={
        <AppContextProvider>
          <AppLayout>
            <Outlet />
          </AppLayout>
        </AppContextProvider>
      }
    >
      <Route path="/" element={<Home />} />
      <Route element={<AuthRoutes />} loader={sessionLoader}>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
      </Route>
      <Route path="/tutorial" element={<Tutorial />}>
        <Route path="your-urine" element={<YourUrine />} />
        <Route path="our-goal" element={<OurGoal />} />
        <Route index element={<Navigate to="your-urine" replace />} />
      </Route>
      <Route element={<ProtectedRoutes />} loader={sessionLoader}>
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/results/:id" element={<SingleResultPage />} />
      </Route>
      <Route path="/scan/:stepId?" element={<Scan />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Route>
  )
);
