import HomeImage1 from "../assets/img/home1.jpg";
import HomeImage2 from "../assets/img/home2.png";
import useMeasure from "react-use-measure";
import { useSpring, animated } from "@react-spring/web";
import Header from "@/components/AppHeader";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useAppContext } from "@/contexts/appContext";
import { useCallback } from "react";

enum FadeDirection {
  Left = "left",
  Right = "right",
}

const animationConfig = {
  friction: 30,
};

const gridCols = [0, "1fr", "100px", "100px", "100px", "100px", "100px", 0];
const colsCount = gridCols.length;
const colors = ["#98CF94", "#FDFADE", "#FFC8C9"];

const image1Style = {
  gridColumn: `${colsCount - 2}/${colsCount}`,
  gridRow: "1/3",
  backgroundImage: `url(${HomeImage1})`,
};

const image2Style = {
  gridColumn: `${colsCount - 4}/${colsCount - 2}`,
  gridRow: "2/4",
  backgroundImage: `url(${HomeImage2})`,
};

const gridStyle = {
  gridTemplateColumns: gridCols.join(" "),
};

const getFadeAnimationSteps = (direction: FadeDirection) => {
  return {
    from: {
      opacity: 0,
      x: direction === FadeDirection.Left ? "-100%" : "100%",
    },
    to: {
      opacity: 1,
      x: "0%",
    },
  };
};

export default function Home() {
  const [gridRef, gridBounds] = useMeasure();
  const { isNavigationInteracted, isLoggedIn } = useAppContext();

  const animationDelay = isNavigationInteracted ? 150 : 2000;

  const [fadeLeft] = useSpring(
    () => ({
      ...getFadeAnimationSteps(FadeDirection.Left),
      delay: animationDelay,
      config: animationConfig,
    }),
    [animationDelay]
  );

  const [fadeRight] = useSpring(
    () => ({
      ...getFadeAnimationSteps(FadeDirection.Right),
      delay: animationDelay,
      config: animationConfig,
    }),
    [animationDelay]
  );

  const renderHeaderContent = useCallback(() => {
    return (
      <>
        <div className="text-ribbon-slate-gray">
          <h2 className="font-medium text-2xl font-extrabold mb-4">
            Welcome To Ribbon.
          </h2>
          <p className="text-xl">
            Easy & accurate, diagnostic testing - All from the comfort of your
            home.
          </p>
        </div>
        <div className="mt-10 flex flex-col gap-4 max-w-md">
          <Button asChild>
            <Link to="/tutorial">Begin Testing</Link>
          </Button>
          {!isLoggedIn && (
            <Button asChild variant="outline">
              <Link to="/sign-in">Sign In</Link>
            </Button>
          )}
        </div>
      </>
    );
  }, [isLoggedIn]);

  return (
    <>
      <Header renderContent={renderHeaderContent} />
      <div className="py-8">
        <div
          className="relative max-w-full overflow-hidden"
          style={{ height: gridBounds.height }}
        >
          <div
            className="grid auto-rows-[100px] gap-[20px] min-w-full absolute right-0"
            style={gridStyle}
            ref={gridRef}
          >
            <animated.div
              className="rounded-r-3xl"
              style={{
                background: colors[0],
                gridColumn: `1/${colsCount - 2}`,
                ...fadeLeft,
              }}
            />
            <animated.div
              className="bg-cover rounded-3xl"
              style={{ ...image1Style, ...fadeRight }}
            />
            <animated.div
              className="bg-cover rounded-3xl"
              style={{ ...image2Style, ...fadeLeft }}
            />
            <animated.div
              className="rounded-r-3xl"
              style={{
                background: colors[1],
                gridColumn: `1/${colsCount - 5}`,
                ...fadeLeft,
              }}
            />
            <animated.div
              className="rounded-3xl"
              style={{
                background: colors[2],
                ...fadeLeft,
              }}
            />
            <animated.div
              className="rounded-r-3xl"
              style={{
                background: colors[2],
                gridColumn: `1/${colsCount - 4}`,
                ...fadeLeft,
              }}
            />
            <animated.div
              className="rounded-l-3xl"
              style={{
                background: colors[2],
                gridColumn: `${colsCount - 2}/ ${colsCount + 1}`,
                ...fadeRight,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
