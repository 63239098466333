import {
  ABNORMAL_DETAILS,
  PARAMETER_TITLE,
  PREFIX,
  STATE_LIST,
  SUFFIX,
} from "@/utils/variables";
import { useMemo } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import PredictionRange from "./PredictionRange";
import { isPredictionNormal } from "@/utils/scanUtils";
import { COLORS } from "@/utils/colors";

interface IProps {
  data: {
    predictions: {
      [key: string]: number;
    };
    reagent_colors: {
      [key: string]: number[];
    };
  };
}

export default function ScanResult({ data }: IProps) {
  const { stateData } = useMemo(() => {
    const { predictions } = data;

    const stateData = STATE_LIST.map((item) => {
      const { title, key } = item;
      const parameters = item.parameters.map((parameter) => {
        const prefix = PREFIX[parameter][predictions[parameter]];
        const suffix = SUFFIX[parameter][predictions[parameter]];
        const state = `${prefix} ${suffix}`;
        const details = ABNORMAL_DETAILS[parameter];
        const isNormal = isPredictionNormal(parameter, predictions);

        const predictionMax = Math.max(PREFIX[parameter].length - 1, 0);
        const predictionValue = predictions[parameter];

        const title = PARAMETER_TITLE[parameter];

        return {
          state,
          details,
          predictionMax,
          predictionValue,
          title,
          isNormal,
        };
      });

      return { title, key, parameters };
    });

    return {
      stateData,
    };
  }, [data]);

  return (
    <>
      <div className="p-6 pb-0 grow flex flex-col">
        <div>
          <Accordion type="multiple" className="w-full space-y-6">
            {stateData.map((item) => {
              const { key, title, parameters } = item;
              const isAbnormal = !!parameters.find((p) => p.isNormal === false);

              const bageBg = isAbnormal
                ? COLORS.rose["300"]
                : COLORS.teal["100"];
              const bageFg = isAbnormal
                ? COLORS.rose["700"]
                : COLORS.teal["700"];
              const bageLabel = isAbnormal ? "Abnormal" : "Normal";

              return (
                <AccordionItem
                  key={key}
                  value={key}
                  className="rounded-lg shadow"
                  disabled={parameters.length === 0}
                >
                  <AccordionTrigger className="text-left !no-underline p-5">
                    <div className="flex justify-between grow pr-4 items-center">
                      <div className="font-normal text-xl">{title}</div>
                      <div
                        className="px-3 py-1 rounded-md font-medium"
                        style={{ background: bageBg, color: bageFg }}
                      >
                        {bageLabel}
                      </div>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="text-base p-5 py-0 border-t border-gray-200">
                    {parameters.map((parameter, index) => {
                      const {
                        details,
                        title,
                        state,
                        predictionMax,
                        predictionValue,
                      } = parameter;

                      return (
                        <div
                          key={index}
                          className="[&:not(:last-child)]:border-b border-gray-100 py-5"
                        >
                          <div className="mb-4 flex items-center justify-between">
                            <div>
                              <div className="font-bold">{title}</div>
                              <div className="text-sm">{state}</div>
                            </div>
                            <div>
                              <PredictionRange
                                max={predictionMax}
                                value={predictionValue}
                              />
                            </div>
                          </div>
                          <div className="text-ribbon-slate-700">{details}</div>
                        </div>
                      );
                    })}
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </>
  );
}
