import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { ISignUpStepProps } from "@/types/common";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as zod from "zod";

const needsList = [
  "Kidney",
  "Liver",
  "Heart",
  "UTI",
  "General Health",
  "Auto-Immune Diseases",
];

const FormSchema = zod.object({
  needs: zod.string().array(),
});

export default function DefineYourNeeds({ onStepSubmit }: ISignUpStepProps) {
  const form = useForm<zod.infer<typeof FormSchema>>({
    mode: "onBlur",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      needs: [],
    },
  });

  const handleSubmit = form.handleSubmit(onStepSubmit);

  return (
    <div className="mt-0">
      <h3 className="mb-4 text-lg font-medium">
        What will you primarily use Ribbon for?
      </h3>
      <p className="mb-4">
        This will help us to send you relevant information to what you’re
        testing. Select all that apply. You can add more, later.
      </p>
      <Form {...form}>
        <div className="space-y-2">
          {needsList.map((item, index) => (
            <FormField
              key={index}
              control={form.control}
              name="needs"
              render={({ field }) => {
                return (
                  <FormItem
                    key={index}
                    className="border-b py-4 flex flex-row items-center justify-between space-x-3 space-y-0"
                  >
                    <FormLabel className="font-medium text-lg grow cursor-pointer">
                      {item}
                    </FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(item)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, item])
                            : field.onChange(
                                field.value?.filter(
                                  (value: string) => value !== item
                                )
                              );
                        }}
                      />
                    </FormControl>
                  </FormItem>
                );
              }}
            />
          ))}
        </div>
      </Form>
      <div className="mt-auto text-center py-6 sticky bottom-0 bg-white">
        <div className="flex">
          <Button
            variant="outline"
            className="grow"
            disabled={!form.formState.isValid}
            onClick={handleSubmit}
          >
            Sign Up
          </Button>
        </div>
      </div>
    </div>
  );
}
