import { ChangeEvent, useCallback } from "react";
import InputWithFloatingLabel from "./InputWithFloatingLabel";
import { withMask } from "use-mask-input";

interface IProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
}

function PhoneNumberInput({ value, onChange, label }: IProps) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const filtered = value.replace(/[^0-9+]/g, "");
      onChange(filtered);
    },
    [onChange]
  );

  return (
    <InputWithFloatingLabel
      label={label}
      ref={withMask("+1 (999) 999-9999")}
      onChange={handleChange}
    />
  );
}

export default PhoneNumberInput;
