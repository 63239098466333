/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "@/components/ui/button";
import { Form, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { ISignUpStepProps } from "@/types/common";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as zod from "zod";
import { isValidPhoneNumber } from "libphonenumber-js";
import PhoneNumberInput from "@/components/PhoneNumberInput";

const PhoneNumberFormSchema = zod.object({
  phoneNumber: zod
    .string()
    .min(1)
    .refine((value) => {
      const result = isValidPhoneNumber(value);
      return result;
    }),
});

export default function PhoneNumber({
  onStepSubmit,
  isLoading = false,
}: ISignUpStepProps) {
  const phoneNumberForm = useForm<zod.infer<typeof PhoneNumberFormSchema>>({
    mode: "onBlur",
    resolver: zodResolver(PhoneNumberFormSchema),
    defaultValues: {
      phoneNumber: "",
    },
  });

  return (
    <>
      <div className="space-y-4 grow flex flex-col">
        <Form {...phoneNumberForm}>
          <FormField
            control={phoneNumberForm.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem className="space-y-0">
                <PhoneNumberInput
                  value={field.value}
                  onChange={field.onChange}
                  label="Cell Phone Number"
                />
                <FormMessage className="px-4" />
              </FormItem>
            )}
          />
        </Form>
        <div className="mt-auto text-center py-6 sticky bottom-0 bg-white">
          <div className="flex">
            <Button
              variant="outline"
              className="grow"
              disabled={isLoading || !phoneNumberForm.formState.isValid}
              onClick={phoneNumberForm.handleSubmit(onStepSubmit)}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
