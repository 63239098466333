/* eslint-disable jsx-a11y/anchor-is-valid */
import InputWithFloatingLabel from "@/components/InputWithFloatingLabel";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { ISignUpStepProps } from "@/types/common";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as zod from "zod";

const FormSchema = zod.object({
  firstName: zod.string().min(1),
  lastName: zod.string().min(1),
  email: zod.string().email(),
  acceptTerms: zod.literal(true),
});

export default function PersonalInformation({
  onStepSubmit,
  isLoading,
}: ISignUpStepProps) {
  const form = useForm<zod.infer<typeof FormSchema>>({
    mode: "onBlur",
    resolver: zodResolver(FormSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  });

  const handleStepSubmit = form.handleSubmit(onStepSubmit);

  return (
    <>
      <div className="space-y-4 grow flex flex-col">
        <Form {...form}>
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="space-y-0">
                <InputWithFloatingLabel label="First Name" {...field} />
                <FormMessage className="px-4" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="space-y-0">
                <InputWithFloatingLabel label="Last Name" {...field} />
                <FormMessage className="px-4" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="space-y-0">
                <InputWithFloatingLabel label="Email Address" {...field} />
                <FormMessage className="px-4" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="acceptTerms"
            render={({ field }) => (
              <FormItem className="!mt-auto pt-8 flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel className="text-sm font-normal">
                    I certify that I am 18 years of age or older, I agree to the{" "}
                    <Button asChild variant="link" className="p-0 h-auto">
                      <a href="#">User Agreement</a>
                    </Button>
                    , and I have read the{" "}
                    <Button asChild variant="link" className="p-0 h-auto">
                      <a href="#">Privacy Policy</a>
                    </Button>
                    .
                  </FormLabel>
                </div>
              </FormItem>
            )}
          />
        </Form>
        <div className="mt-auto text-center py-6 sticky bottom-0 bg-white">
          <div className="flex">
            <Button
              variant="outline"
              className="grow"
              disabled={isLoading || !form.formState.isValid}
              onClick={handleStepSubmit}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
