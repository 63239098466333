import { AuthError } from "@supabase/supabase-js";
import { toast } from "sonner";

export function handleOtpSendError(error: AuthError) {
  const { message, code } = error;
  let notificationMessage = "Error";

  switch (code) {
    case "otp_disabled":
      if (message.startsWith("Signups not allowed"))
        notificationMessage =
          "No account associated with your phone number. Please, sign up";
      break;
    case "sms_send_failed":
      if (message.includes("Invalid 'To' Phone Number"))
        notificationMessage = "Failed to send auth code to your phone number";
      break;
    default:
      break;
  }

  toast.error(notificationMessage);
  console.error(error);
}

export function getTenDigitsPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/\D/g, "").slice(-10);
}
