import Header from "@/components/AppHeader";
import Spinner from "@/components/Spinner";
import { Button } from "@/components/ui/button";
import { useAppContext } from "@/contexts/appContext";
import { supabaseService } from "@/services/supabaseService";
import { QUERY_KEY } from "@/utils/variables";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { Link } from "react-router-dom";

export default function ResultsPage() {
  const { session } = useAppContext();
  const supabase = supabaseService.getClient();

  const resultsQuery = useQuery({
    queryKey: [QUERY_KEY.scanResults],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("UserScan")
        .select("*, Scan (*, Result (*))")
        .eq("user_uid", session.user.id)
        .order('created_at', { ascending: false });

      if (error) {
        throw error;
      } else {
        return data;
      }
    },
    enabled: !!session?.user.id,
  });

  const renderHeaderContent = useCallback(() => {
    const userName = session?.user?.user_metadata.first_name;

    return (
      <div className="max-w-screen-sm">
        {userName && (
          <div className="mb-8 text-2xl font-medium">Hello, {userName}</div>
        )}
        <Button asChild variant="outline" className="border-none w-full">
          <Link to="/tutorial">Scan Your Strip</Link>
        </Button>
      </div>
    );
  }, [session]);

  return (
    <>
      <Header renderContent={renderHeaderContent} />
      <div className="p-6">
        <h2 className="mb-6 text-lg">Previous Test Results</h2>
        {resultsQuery.isLoading && (
          <div className="">
            <Spinner />
          </div>
        )}
        {resultsQuery.isSuccess && resultsQuery.data.length === 0 && (
          <div>No results found.</div>
        )}
        {resultsQuery.isSuccess && (
          <ul className="space-y-5">
            {resultsQuery.data.map((item) => {
              const locale = "en";
              const scanDate = DateTime.fromISO(item.created_at).setLocale(
                locale
              );
              const localDate = DateTime.local().setLocale(locale);
              const diff = localDate.diff(scanDate, ["days"]);
              const diffInDays = Math.floor(diff.days);
              let dateText = scanDate.toFormat("DDD t");

              switch (true) {
                case diffInDays === 0:
                  dateText = `Today at ${scanDate.toFormat("t")}`;
                  break;
                case diffInDays < 31:
                  dateText = `${diffInDays} day${
                    diffInDays === 1 ? "" : "s"
                  } ago`;
                  break;
                default:
                  break;
              }

              return (
                <li key={item.id}>
                  <Link
                    to={`/results/${item.id}`}
                    className="block p-5 rounded border border-gray-300 bg-gray-100"
                  >
                    <span className="text-primary font-medium">{dateText}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
}
