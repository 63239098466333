import Header from "@/components/AppHeader";
import { Progress } from "@/components/ui/progress";
import { ISignUpStep } from "@/types/common";
import { useCallback, useMemo, useState } from "react";
import PersonalInformation from "./PersonalInformation";
import DefineYourNeeds from "./DefineYourNeeds";
import { supabaseService } from "@/services/supabaseService";
import { toast } from "sonner";
import PhoneNumber from "./PhoneNumber";
import AuthCode from "./AuthCode";
import { Navigate, useNavigate } from "react-router-dom";
import { handleOtpSendError } from "@/utils/common";
import { useAppContext } from "@/contexts/appContext";

enum Step {
  PhoneNumber = "phoneNumber",
  Code = "code",
  PersonalInfo = "personalInfo",
  Needs = "needs",
}

interface SignUpData {
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  needs?: string[];
}

const steps: ISignUpStep[] = [
  {
    id: Step.PhoneNumber,
    name: "Phone Number",
    component: PhoneNumber,
  },
  {
    id: Step.Code,
    name: "Code",
    component: AuthCode,
  },
  {
    id: Step.PersonalInfo,
    name: "Personal Information",
    component: PersonalInformation,
  },
  {
    id: Step.Needs,
    name: "Define Your Needs",
    component: DefineYourNeeds,
  },
];

export default function SignUp() {
  const [stepId, setStepId] = useState<Step>(Step.PhoneNumber);
  const [signUpData, setSignUpData] = useState<SignUpData>({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { reloadSession, session } = useAppContext();

  const { currentStep, currentStepIndex } = useMemo(() => {
    const currentStep = steps.find((step) => step.id === stepId);
    const currentStepIndex = steps.findIndex((step) => step.id === stepId);

    return {
      currentStep,
      currentStepIndex,
    };
  }, [stepId]);

  const sendAuthCode = useCallback(async (phoneNumber: string) => {
    setIsLoading(true);
    const supabaseClient = supabaseService.getClient();
    const { error } = await supabaseClient.auth.signInWithOtp({
      phone: phoneNumber,
    });

    if (error) {
      handleOtpSendError(error);
    } else {
      toast.success("Code has been sent");
      setStepId(Step.Code);
      setSignUpData((current) => ({ ...current, phoneNumber }));
    }

    setIsLoading(false);
  }, []);

  const verifyAuthCode = useCallback(
    async (authCode: string) => {
      setIsLoading(true);
      const supabase = supabaseService.getClient();

      const { error } = await supabase.auth.verifyOtp({
        phone: signUpData.phoneNumber!,
        token: authCode,
        type: "sms",
      });

      if (error) {
        const message = error.message || "Error";
        toast.error(message);
      } else {
        toast.success("Verified");
        reloadSession();
        setStepId(Step.PersonalInfo);
      }

      setIsLoading(false);
    },
    [signUpData.phoneNumber, reloadSession]
  );

  const handleSubmitPersonalInfo = useCallback((data: any) => {
    setSignUpData((current) => ({ ...current, ...data }));
    setStepId(Step.Needs);
  }, []);

  const handleSubmitNeedsStep = useCallback(
    async (data: any) => {
      const supabase = supabaseService.getClient();
      const { needs } = data;
      const userData = {
        data: {
          first_name: signUpData.firstName!,
          last_name: signUpData.lastName!,
          email: signUpData.email!,
          phone_number: signUpData.phoneNumber!,
          needs,
        },
      };

      setIsLoading(true);
      const { error } = await supabase.auth.updateUser(userData);

      if (error) {
        const message = error.message || "Error";
        toast.error(message);
      } else {
        toast.success("Success");
        reloadSession();
        navigate("/", { replace: true });
      }

      setIsLoading(false);
    },
    [navigate, reloadSession, signUpData]
  );

  const handleStepSubmit = useCallback(
    (data: any) => {
      console.log(data, "STEP SUBMIT");

      switch (currentStep?.id) {
        case Step.PhoneNumber:
          sendAuthCode(data.phoneNumber);
          break;
        case Step.Code:
          if (data.resend === true) {
            sendAuthCode(signUpData.phoneNumber!);
          } else {
            verifyAuthCode(data.code);
          }
          break;
        case Step.PersonalInfo:
          handleSubmitPersonalInfo(data);
          break;
        case Step.Needs:
          handleSubmitNeedsStep(data);
          break;
        default:
          break;
      }
    },
    [
      currentStep?.id,
      handleSubmitNeedsStep,
      handleSubmitPersonalInfo,
      sendAuthCode,
      signUpData.phoneNumber,
      verifyAuthCode,
    ]
  );

  const progress = useMemo(() => {
    return ((currentStepIndex + 1) / steps.length) * 100;
  }, [currentStepIndex]);

  const renderHeaderContent = useCallback(() => {
    return (
      <>
        <div className="text-ribbon-slate-gray">
          <h2 className="font-medium text-2xl mb-2">Create An Account</h2>
          <Progress
            value={progress}
            className="h-2"
            indicatorClassName="bg-ribbon-slate-gray"
          />
          {currentStep && (
            <div className="mt-2">
              Step {currentStepIndex + 1} of {steps.length} : {currentStep.name}
            </div>
          )}
        </div>
      </>
    );
  }, [currentStep, currentStepIndex, progress]);

  if (session?.user?.user_metadata?.email) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="px-6 mt-6 grow flex flex-col max-w-screen-sm w-full mx-auto">
      <Header renderContent={renderHeaderContent} />
      {currentStep?.component ? (
        <currentStep.component
          onStepSubmit={handleStepSubmit}
          isLoading={isLoading}
        />
      ) : null}
    </div>
  );
}
